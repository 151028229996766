import * as React from 'react'

import { sidebar, sidebar__wrapper, sidebar__wrapperLeft, sidebar__wrapperRight, sidebarClassic, sidebarClassic__wrapper, sidebarClassic__wrapper__header, box } from './sidebar.module.scss'
import SingleBox from './singlebox'

const Sidebar = ({children, type, stories}) => {
    if (type==="description" || type==="header-mod"){
        return(
            <div className={`${sidebarClassic}`}>
                <div className={`${sidebarClassic__wrapper} ${type==="header-mod" ? sidebarClassic__wrapper__header : '' }`}>
                {stories.map((story, i) => (
                    <SingleBox
                        type="story"
                        data={story}
                        key={i + `st`}
                        className={box}
                    />
                ))}
                <SingleBox type="empty" key="empty" className={box} />
                </div>
            </div>
        )
    } 
    if (type==="left") {
        return(
            <div className={sidebar}>
                <div className={`${sidebar__wrapper} ${sidebar__wrapperLeft}`}>
                    {children}
                </div>
            </div>
        )
    } else {
        return(
            <div className={sidebar}>
                <div className={`${sidebar__wrapper} ${sidebar__wrapperRight}`}>
                    {children}
                </div>
            </div>
        )
    }
}

export default Sidebar