import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import Sidebar from "../components/sidebar"

import {
  main,
  main__container,
  description,
  descriptionAuthor,
  descriptionText,
} from "./descriptions.module.scss"

const Descriptions = ({ data }) => {

  let descriptions = data.allIndexImagesJson.nodes
  let stories = data.allMdx.nodes
  return (
    <Layout pageTitle="Intro">
      <div className={main}>
        <Sidebar type="description" stories={stories}></Sidebar>
        <div className={main__container}>
          {descriptions.map((node, i) => (
            <div className={description} key={`descr+${i}`}>
              <div className={descriptionText}>
                <div><p>{node.intro}</p></div>
                <div className={descriptionAuthor}><p>{node.title}</p></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
      allIndexImagesJson(filter: {intro: {ne: "none"}}) {
        nodes{
          title
          text
          intro
        }
      }
      allMdx(sort: {order: ASC, fields: [frontmatter___title]}) {
        nodes {
          slug
          frontmatter {
            title
            subtitle
          }
        }
      }
    }
`

export default Descriptions
