import * as React from 'react'
import Header from './header'
import Seo from './seo'

const Layout = ({pageTitle, children}) => {
    return(
        <div>
            <Seo title={pageTitle}/>
            <Header></Header>
            {children}
        </div>
    )
}

export default Layout